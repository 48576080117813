<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.update || !subPermission.store"
    />
    <b-card :title="$t('g.basicInformations')" v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!-- ** Name -->
            <b-col md="6">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **  name -->

            <!-- available -->
            <b-col md="6">
              <b-form-group :label="$t('g.IsAvailableCorrectActionType')">
                <div class="d-flex">
                  <b-form-checkbox
                    v-model="form_data.is_available"
                    name="some-radio9"
                    value="1"
                    class="custom-control-success"
                  >
                    {{ $t("g.yes") }}</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="form_data.is_available"
                    name="some-radio9"
                    value="0"
                    class="custom-control-danger mr-1 ml-1"
                  >
                    {{ $t("g.no") }}</b-form-checkbox
                  >
                </div>
              </b-form-group>
            </b-col>

            <!-- ** available -->
            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { required, confirmed, min } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BCard,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      form_data: {
        name: null,
        is_available: 1,
      },

      isEdit: false,
      isLoading: true,
      isError: null,
      currentId: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddCorrectActionType") {
        this.handleWatchRoutePage();
      }
    },
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.getRiskType(this.$route.params.id);
    } else {
      setTimeout(() => {
        this.pageStatus(false, false);
        store.dispatch("GET_PERMISSION", "correctActionTypes");
        this.subPermission = store.state.permissions.sub;
      }, 2000);
    }
  },

  methods: {
    //  GET DATA RiskType IF isEdit true
    getRiskType(id) {
      this.$http
        .get(`admin/correctActionTypes/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data;
            this.form_data.name = data?.name;
            this.form_data.is_available = data?.is_available ? 1 : 0;
            setTimeout(() => {
              this.pageStatus(false, false);
              store.dispatch("GET_PERMISSION", "correctActionTypes");
              this.subPermission = store.state.permissions.sub;
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.isEdit = false;
      this.$refs.form.reset();
      this.clearForm();
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    //  Clear Form Data
    clearForm() {
      this.form_data.name = null;
      this.form_data.is_available = 1;
    },
    // Send the modified data
    submitEdit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item]);
          }

          this.$http
            .post(
              `admin/correctActionTypes/${this.currentId}?_method=put`,
              formData
            )
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListCorrectActionType" });
              }, 500);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit new user
    submitNewAddition() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item]);
          }
          this.$http
            .post("admin/correctActionTypes", formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListCorrectActionType" });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit Function
    validationForm() {
      if (this.isEdit) {
        return this.submitEdit();
      }
      this.submitNewAddition();
    },
  },
};
</script>

<style></style>
